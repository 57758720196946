body {
  margin: 0;
  color: $font-color;
  font-family: $font-style;
  font-size: 1.1em;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}
h1,
h2,
h3 {
  font-weight: 600;
  color: $heading-font-color;
  font-family: $heading-font;
  line-height: 1.5;
}
h1 {
  color: $secondary;
  font-size: 2.5em;
  a,
  a:visited {
    text-decoration: none;
    color: $secondary;
  }
}
h2 {
  font-size: 2em;
}
a,
a:visited {
  color: $link-color;
  text-decoration: underline;
  font-weight: bold;
}
a:hover {
  color: $link-hover-color;
  text-decoration: underline;
}
header {
  background: $header;
  padding: 0px 15px;
  text-align: center;
  margin: 50px 0 0;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
main {
  padding: 70px 15px 0 15px;
  max-width: $content-width;
  margin: 0 auto;

  &.header {
    padding: 0 15px;
  }
}
time {
  color: #898989;
}
.container {
  max-width: $content-width;
  margin: 0 auto;
}
.heavy {
  font-weight: bold;
  font-size: 1.2em;
}

/* Aside */

aside {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid lighten($light, 30%);
  box-shadow: 0px 1px 1px RGBA(4, 25, 54, .1);
  nav {
    float: left;
    max-width: 800px;
    margin: 0 auto;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        float: left;
        position: relative;
        a {
          text-decoration: none;
          display: block;
          padding: 15px;
          font-family: 'Muli', sans-serif;
          line-height: 20px;
          margin-bottom: -1px;
          box-shadow: 0;
          &:hover,
          &:active {
            background: $header;
            text-decoration: none;
            color: $secondary;
          }
        }
      }
    }
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}
footer {
  text-align: center;
  padding: 40px;
}

/* Project Header */

.project-header {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 50px;
    margin-left: 10px;
    position: relative;

    &:hover {
      cursor: pointer;
      top: -5px;
      transition: 2000;
    }
  }
}

/* IFRAME */
.iframe-container {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16x9 Aspect Ratio */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
