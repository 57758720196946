.title {
  font-family: 'Montserrat';
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  letter-spacing: 1px;
}

.title-content {
  background-color: $header-preload;
  background-image: url('/assets/img/header-background.gif');
  color: transparent;
  background-clip: text;
  -moz-background-clip: text;
  -webkit-background-clip: text;
  margin: 10px 0;
}

header {
  h1 {
    font-size: 8em;
    text-transform: uppercase;
    line-height: 0.8;
  }

  h2 {
    font-size: 3.6em;
    text-transform: lowercase;
  }
}
