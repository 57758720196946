/* Layout */
$content-width: 800px;
$large-breakpoint: 975px;
$medium-breakpoint: 823px;
$small-breakpoint: 639px;

/* Colors */

$main: #29466E;
$secondary: #22B5C9;
$font-color: #1F1F21;
$link-color: $main;
$link-hover-color: $secondary;
$heading-font-color: $main;
$light: #E7EDF4;
$header:  #170D26;
$header-preload: #791ebf;

/* Font */
$font-style: 'Muli', sans-serif;
$heading-font: 'Montserrat', sans-serif;

/* Universals */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
